export const validateEmail = (email: string) => {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(email);
};

export const validateInternationalPhoneNumber = (phoneNumber: string) => {
    const regex = /^(00|\+)\d+$/;

    return regex.test(phoneNumber);
};

export const emailRegex = /\S+@\S+\.\S+/;
export const internationalPhoneNumberRegex = /^(00|\+)\d+$/;
export const phoneNumberRegex = /^[+\d]\d*$/;
