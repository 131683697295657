import React, { useCallback, useEffect, useRef } from 'react';
import ArrowDown from '$icons/arrow-down.svg';
import Checkmark from '$icons/checkmark.svg';
import { Icon, InputField } from '$shared/components';
import { useTheme } from '@emotion/react';
import { useId } from '@radix-ui/react-id';
import { useCombobox } from 'downshift';
import { AnimatePresence } from 'framer-motion';
import { AnimatedAutoHeight } from '~/shared/components/AnimatedAutoHeight/AnimatedAutoHeight';
import { Option } from '.';
import {
    StyledIcon,
    StyledOptions,
    StyledOptionsWrapper,
    StyledSelectedOptions,
    StyledSelector,
} from './styled';
import { InputColorTheme } from '../../types';

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

export type SelectorOption = {
    value: string;
    title?: string;
    disabled?: boolean;
};

export type SelectorProps = InputProps & {
    /**
     * Adds a label to the input field. This is required for accessibilty.
     */
    label?: string;

    /**
     * Add an additional help text below the input field.
     */
    helpText?: string;

    /**
     * Add an additional help text below the input field.
     */
    invalidMessage?: string;

    /**
     * Set styling to indicate input is invalid.
     * Also shows the `invalidMessage` if provided
     */
    isInvalid?: boolean;

    /**
     * The selected Option
     */
    value?: string;

    /**
     *
     */
    options: SelectorOption[];
    onChangeHandler?: (option: SelectorOption | undefined | null) => void;

    /**
     * Set max height limit for dropdown.
     */
    maxHeight?: number;

    /**
     * Simple implementation of a theme for the input field
     */
    theme?: InputColorTheme;
};

const SelectorComponent = (
    { options, onChangeHandler, maxHeight, onClick, theme = 'grey', value, ...rest }: SelectorProps,
    ref: React.Ref<HTMLInputElement>,
) => {
    const id = useId();
    const listRef = useRef<HTMLDivElement>(null);
    const { animations } = useTheme();

    const {
        isOpen,
        getToggleButtonProps,
        getLabelProps,
        getInputProps,
        getMenuProps,
        highlightedIndex,
        getItemProps,
        closeMenu,
        openMenu,
        reset,
    } = useCombobox<SelectorOption>({
        items: options,
        id,
        itemToString: (item) => item?.value || '',
        onSelectedItemChange: ({ selectedItem }) => {
            onChangeHandler && onChangeHandler(selectedItem);
            closeMenu();
        },
    });

    useEffect(() => {
        if (value === '') {
            reset();
        }
    }, [value, reset]);

    const handleInputClick = useCallback(
        (event: React.MouseEvent<HTMLInputElement>) => {
            if (rest.disabled) return;
            onClick && onClick(event);
            isOpen ? closeMenu() : openMenu();
        },
        [isOpen, onClick, rest.disabled, closeMenu, openMenu],
    );

    const handleKeyDown = useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.nativeEvent.code === 'Space') {
                event.nativeEvent.preventDefault();
                if (!isOpen) {
                    openMenu();
                    listRef.current?.focus();
                } else {
                    closeMenu();
                    (event.target as HTMLInputElement).focus();
                }
            }
        },
        [isOpen, openMenu, closeMenu],
    );

    const inputProps = getInputProps(
        {
            ...rest,
            value,
            ref,
            readOnly: true,
            type: 'text',
            onClick: handleInputClick,
            onKeyDown: handleKeyDown,
        },
        { suppressRefError: true },
    );

    const openIcon = (
        <StyledIcon isOpen={isOpen}>
            <Icon>
                <ArrowDown aria-hidden="true" />
            </Icon>
        </StyledIcon>
    );

    return (
        <StyledSelector disabled={rest.disabled ?? false}>
            <InputField
                theme={theme}
                {...inputProps}
                isActive={isOpen}
                append={openIcon}
                {...getToggleButtonProps()}
                {...getLabelProps()}
            >
                <StyledOptionsWrapper ref={listRef}>
                    <AnimatedAutoHeight
                        maxHeight={maxHeight}
                        {...getMenuProps({}, { suppressRefError: true })}
                    >
                        <AnimatePresence>
                            {isOpen && (
                                <StyledOptions
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ ...animations.springDefault }}
                                >
                                    {options.map((item, index) => (
                                        <div key={item.value} style={{ position: 'relative' }}>
                                            <Option
                                                selected={inputProps.value === item.value}
                                                {...item}
                                                {...getItemProps({
                                                    item,
                                                    disabled: item.disabled,
                                                    index,
                                                })}
                                            >
                                                {item.title || item.value}
                                                {inputProps.value === item.value && (
                                                    <Icon size="md">
                                                        <Checkmark />
                                                    </Icon>
                                                )}
                                            </Option>
                                            <AnimatePresence>
                                                {highlightedIndex === index && (
                                                    <StyledSelectedOptions
                                                        initial={{
                                                            opacity: 0,
                                                            transform:
                                                                'translate3d(0, -1rem, 0), scale3d(1, 1, 1)',
                                                        }}
                                                        animate={{
                                                            opacity: 1,
                                                            transform:
                                                                'translate3d(0, 0rem, 0), scale3d(1, 1, 1)',
                                                        }}
                                                        transition={{
                                                            type: 'spring',
                                                            stiffness: 300,
                                                            damping: 30,
                                                        }}
                                                        layoutId="selected"
                                                    />
                                                )}
                                            </AnimatePresence>
                                        </div>
                                    ))}
                                </StyledOptions>
                            )}
                        </AnimatePresence>
                    </AnimatedAutoHeight>
                </StyledOptionsWrapper>
            </InputField>
        </StyledSelector>
    );
};

export const Selector = React.forwardRef(SelectorComponent);
export default Selector;
